import React from "react";
import { Link } from "react-router-dom";

function Bottom() {
  return (
    <>
      <div className="bottom section-padding pb-0">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="bottom-logo">
                <img className="pb-3" src={require("../../images/logo.png")} alt="" style={{ maxWidth: "200px" }} />
                <p>
                  Ethereum, Polygon and BSC integration <br /> Decentralized/Hybrid trading <br /> A+ ranking for
                  transparency and volume
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-md-3">
              <div className="bottom-widget">
                <h3 className="widget-title">Company</h3>
                <ul>
                  <li>
                    <Link to={"/aboutus"}>About us</Link>
                  </li>
                  <li>
                    <Link to={"/token-info"}>Listed assets</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Affiliated companies</Link>
                  </li>
                  <li>
                    <Link to={"/api-doc"}>API Documentation</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-md-3">
              <div className="bottom-widget">
                <h3 className="widget-title">Quick link</h3>
                <ul>
                  <li>
                    <Link to={{ pathname: "https://www.facebook.com/caladexorg" }} target={`_blank`}>
                      Facebook
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "https://twitter.com/caladexorg" }} target={`_blank`}>
                      Twitter
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "https://www.linkedin.com/in/antony-kamermans/" }} target={`_blank`}>
                      Linkedin
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{ pathname: "https://discord.com/channels/942107737637146695/942107737637146697" }}
                      target={`_blank`}
                    >
                      Discord
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "https://www.facebook.com/groups/1828044877265988" }} target={`_blank`}>
                      Community
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start py-3 gap-4 bottom-footer">
            <Link to={"/terms-of-use"}>Terms of use</Link>
            <Link to={"/privacy-policy"}>Privacy policy</Link>
            <Link to={"/cookie-policy"}>Cookie policy</Link>
            <Link to={"/esign-consent"}>E-Sign consent</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bottom;
