import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import { isEmpty } from "lodash";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";

function PageTitle({ username }) {
  const { address, isConnected } = useAccount();

  const { open, close } = useWeb3Modal();

  const { currentUser, isAuthenticated } = useContext(AuthContext);

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setTimeout(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 600);
    }, 100);
  };

  return (
    <>
      <div className="page-title dashboard">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <div className="page-title-content">
                <div className="text-14">
                  Welcome Back,
                  <span> {username}</span>
                  <BrowserView>
                    {isConnected ? (
                      <>
                        <span className="ml-3 text-success">{isConnected ? address : ` - `}</span> &nbsp;
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip className="text-primary">{copied ? `Copied` : `Copy`}</Tooltip>}
                        >
                          <CopyToClipboard text={address}>
                            <span onClick={handleCopy}>
                              <i className="fa fa-copy text-danger" style={{ cursor: `pointer` }}></i>
                            </span>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <span
                        className="ml-3 text-danger"
                        style={{ cursor: `pointer` }}
                        onClick={() => {
                          open();
                        }}
                      >
                        Please connect wallet to manage your balance.
                      </span>
                    )}
                  </BrowserView>
                </div>
                <MobileOnlyView>
                  <div>
                    {isConnected ? (
                      <>
                        <span className="d-block text-center my-3 text-success">{isConnected ? address : ` - `}</span>
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip className="text-primary">{copied ? `Copied` : `Copy`}</Tooltip>}
                        >
                          <CopyToClipboard text={address}>
                            <span onClick={handleCopy}>
                              <i className="fa fa-copy text-danger" style={{ cursor: `pointer` }}></i>
                            </span>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <span
                        className="d-block text-center my-3 text-danger"
                        style={{ cursor: `pointer` }}
                        onClick={() => {
                          open();
                        }}
                      >
                        Please connect wallet to manage your balance.
                      </span>
                    )}
                  </div>
                </MobileOnlyView>
              </div>
            </div>
            <div className="col-4 text-end">
              {isAuthenticated && !isEmpty(currentUser) && <Link to={"/settings"}>Settings </Link>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageTitle;

// 0170 55 99 058
