import { isUndefined } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { USER_STATUS } from "../../shared/constants";

function SettingsSubmenu({ activePage, data }) {
  return (
    <>
      <ul className="d-flex">
        <li className={`nav-item`}>
          <Link to={"/settings"} className={`nav-link ${activePage == "settings" ? `active` : ``}`}>
            <i className="mdi mdi-account-settings-variant"></i>
            <span>Edit Profile</span>
          </Link>
        </li>
        {/* <li className="nav-item">
                    <Link to={"/settings-preferences"} className="nav-link">
                        <i className="mdi mdi-settings"></i>
                        <span>Preferences</span>
                    </Link>
                </li> */}
        <li className={`nav-item`}>
          <Link to={"/settings-security"} className={`nav-link ${activePage == "security" ? `active` : ``}`}>
            <i className="mdi mdi-lock"></i>
            <span>Security</span>
          </Link>
        </li>
        {!isUndefined(data) && data?.user?.status === USER_STATUS.VERIFYING ? (
          <li className={`nav-item`}>
            <Link to={`/account-overview`} className={`nav-link text-danger`}>
              <i className="mdi mdi-alert"></i>Your account is under verification.
            </Link>
          </li>
        ) : data?.user?.status === USER_STATUS.VERIFIED ? (
          <li className={`nav-item`}>
            <Link to={`/account-overview`} className={`nav-link text-success`}>
              <i className="mdi mdi-check"></i>Your account is verified.
            </Link>
          </li>
        ) : null}
        {/* <li className="nav-item">
                    <Link to={"./settings-account"} className="nav-link">
                        <i className="mdi mdi-bank"></i>
                        <span>Linked Account</span>
                    </Link>
                </li> */}
      </ul>
    </>
  );
}

export default SettingsSubmenu;
