import React, { useContext, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isUndefined } from "lodash";
import moment from "moment";
import { BrowserView } from "react-device-detect";
import { CircularProgress } from "@mui/material";

import Testimonial from "../element/testimonial";
import Footer1 from "../layout/footer1";
import Header1 from "../layout/header1";
import Bottom from "./../element/bottom";
import BannerVideo from "../../images/logo-video.mp4";
import BannerVideo2 from "../../images/logo-video-2.mp4";
import { atomTokenLandingList } from "../../state/atoms";
import { AuthContext } from "../../contexts/AuthContext";
import {
  BACKEND_FILE_URL,
  CHAIN_LIST,
  SWALLOW_DEGITS,
  TOKEN_DIGITS,
  LANDING_PRIORITY_PAIRS,
} from "../../shared/constants";
import { formatDegits } from "../../shared/helpers";
import LandingChart from "../charts/landingChart";
import { useNewsList } from "../../state/hooks";
import { ImageContainer } from "./exchange";

function Homepage() {
  const { isTokenLandingListRefetching, isTokenLandingListSuccess } = useContext(AuthContext);

  const history = useHistory();

  const tokenLandingList = useRecoilValue(atomTokenLandingList);

  const { data: newsList } = useNewsList();

  const videoRef = useRef();
  const videoRef2 = useRef();

  const [displayedRows, setDisplayedRows] = useState(7);
  const [showAllItems, setShowAllItems] = useState(false);

  const handleViewToggle = () => {
    setShowAllItems(!showAllItems);
    setDisplayedRows(7);
  };

  const handleViewMore = () => {
    setShowAllItems(!showAllItems);
    setDisplayedRows(tokenLandingList.length);
  };

  const playVideo = () => {
    setTimeout(() => {
      videoRef2.current.style.transition = "all 2s ease";
      videoRef2.current.style.opacity = 0.7;
      setTimeout(() => {
        videoRef2.current.style.opacity = 0;
      }, 2000);
      if (videoRef.current) {
        videoRef.current.style.transition = "all 2s ease";
      }
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.style.opacity = 1;
          videoRef.current.getElementsByClassName("video-tag")[0].play();
        }
      }, 3000);
    }, 500);
  };

  const getUniqueTokens = () => {
    const utillityTokenList = [];
    tokenLandingList.map((value, index, array) => {
      if (utillityTokenList.find((token) => token.token_id === value.token_id)) {
        return false;
      }
      utillityTokenList.push(value);
      return true;
    });

    const result = utillityTokenList.sort((a, b) => {
      const aPriority = LANDING_PRIORITY_PAIRS.includes(a.address);
      const bPriority = LANDING_PRIORITY_PAIRS.includes(b.address);

      if (aPriority === bPriority) {
        return 0;
      }

      if (aPriority) {
        return -1;
      }

      return 1;
    });
    return result;
  };

  return (
    <>
      <Header1 />
      <div className="video-background" ref={videoRef2}>
        <video
          style={{ borderRadius: "20px", width: "100vw", minHeight: "100vh" }}
          autoPlay
          muted
          onEnded={() => {
            playVideo();
          }}
        >
          <source src={BannerVideo2} type="video/mp4" />
        </video>
      </div>
      <div className="intro" id="home">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="intro-content">
                <h1>
                  Trade with <strong className="text-primary">Caladex</strong>. <br /> Enterprise Tokens with Real Value
                </h1>
                <p>A globally distributed team pushing tokenization worldwide</p>
              </div>
              <div className="d-flex">
                <div className="intro-btn">
                  <Link to={"/exchange/polygon/cax_dai"} className="btn btn-primary">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <BrowserView className="col-xl-6 col-lg-6 col-12">
              <div className="intro-form-exchange" ref={videoRef}>
                <video width="100%" height="auto" muted className="video-tag">
                  <source src={BannerVideo} type="video/mp4" />
                </video>
              </div>
            </BrowserView>
          </div>
        </div>
      </div>
      <div className="price-grid section-padding" id="price">
        <div className="container">
          <div className="row">
            {window.innerWidth > 576 ? (
              <div className="card">
                <div className="card-header fw-bold"></div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 table-responsive-sm" style={{ display: "inline-table" }}>
                      <thead>
                        <tr>
                          <th>
                            <div>Asset</div>
                          </th>
                          <th>
                            <div>Price / Volume</div>
                          </th>
                          <th>
                            <div>Change (24H)</div>
                          </th>
                          <th style={{ width: "25%", paddingLeft: 36 }}>
                            <div>Chart</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isTokenLandingListSuccess ? (
                          getUniqueTokens()
                            .slice(0, displayedRows)
                            .map((_pair, index) => (
                              <tr key={`token-info-list-${index}`}>
                                <td
                                  className="landing-table-padding"
                                  style={{
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push(
                                      `/exchange/${
                                        CHAIN_LIST.find((chain) => chain.chainId === _pair.chain_id)?.urlName
                                      }/${_pair.symbol.toLowerCase()}_${_pair.pair_token.toLowerCase()}`
                                    );
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div style={{ alignSelf: "center" }}>
                                      <i className="cc">
                                        <ImageContainer
                                          src={`${BACKEND_FILE_URL}/${_pair?.logo_url}`}
                                          style={{ width: "35px" }}
                                        />
                                      </i>
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                      <div className="text-white-80 text-16">{_pair?.name}</div>
                                      <span className="text-white-50">{_pair?.symbol}</span>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-white-50 landing-table-padding">
                                  {formatDegits(_pair.price, TOKEN_DIGITS)}
                                  &nbsp;/&nbsp;
                                  {formatDegits(_pair.volume, SWALLOW_DEGITS)}
                                </td>
                                <td
                                  className={
                                    +_pair.percentChange > 0
                                      ? `text-success landing-table-padding`
                                      : `text-danger landing-table-padding`
                                  }
                                >
                                  {formatDegits(_pair.percentChange, SWALLOW_DEGITS)}%
                                </td>
                                <td className="text-white-50 landing-table-padding">
                                  <LandingChart
                                    chainId={_pair.chain_id}
                                    targetTokenSymbol={_pair.symbol}
                                    pairTokenSymbol={_pair.pair_token}
                                  />
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan={4} className="mx-auto">
                              <div className="d-flex justify-content-center">
                                <CircularProgress />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-center mt-3 mb-3">
                    <button className="btn btn-primary" onClick={showAllItems ? handleViewToggle : handleViewMore}>
                      {showAllItems ? "View less" : "View more"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {!isTokenLandingListRefetching && isTokenLandingListSuccess
                  ? getUniqueTokens()
                      .slice(0, displayedRows)
                      .map((_pair, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={`landing-page-chart-${index}`}>
                          <div
                            className="card"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(
                                `/exchange/${
                                  CHAIN_LIST.find((chain) => chain.chainId === _pair.chain_id)?.urlName
                                }/${_pair.symbol.toLowerCase()}_${_pair.pair_token.toLowerCase()}`
                              );
                            }}
                          >
                            <div className="card-header">
                              <div className="d-flex align-items-center">
                                <span>
                                  <i className="cc">
                                    <ImageContainer
                                      src={`${BACKEND_FILE_URL}/${_pair?.logo_url}`}
                                      width={`50px`}
                                      alt="Token Image"
                                    />
                                  </i>
                                </span>
                                <div className="flex-grow-1">
                                  {_pair.symbol} / {_pair.pair_token} (
                                  {CHAIN_LIST.find((chain) => chain.chainId === _pair.chain_id)?.chainName})
                                </div>
                              </div>
                              <p className="mb-0"> 24h</p>
                            </div>
                            <div className="card-body">
                              <h3>
                                {_pair.symbol} {formatDegits(_pair.price, TOKEN_DIGITS)} (
                                {formatDegits(_pair.volume, SWALLOW_DEGITS)})
                              </h3>
                              <span className={+_pair.percentChange > 0 ? `text-success` : `text-danger`}>
                                {formatDegits(_pair.percentChange, SWALLOW_DEGITS)}%
                              </span>
                              <LandingChart
                                chainId={_pair.chain_id}
                                targetTokenSymbol={_pair.symbol}
                                pairTokenSymbol={_pair.pair_token}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                  : ``}
                <div className="d-flex justify-content-center mt-3 mb-3">
                  <button className="btn btn-primary" onClick={showAllItems ? handleViewToggle : handleViewMore}>
                    {showAllItems ? "View less" : "View more"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="getstart section-padding" id="portfolio">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="section-title">
                <h2>Welcome to the Caladex revolution</h2>
              </div>
            </div>
          </div>
          <div className="row gy-5">
            <div className="col-sm-12 col-md-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-user-plus"></i>
                </span>
                <h3>Zero Fees</h3>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-bank"></i>
                </span>
                <h3>Guaranteed Staking</h3>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="getstart-content">
                <span>
                  <i className="la la-exchange"></i>
                </span>
                <h3>Real Enterprise Tokens</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio section-padding" id="portfolio">
        <div className="container">
          <div className="row py-lg-5 justify-content-center">
            <div className="col-xl-7">
              <div className="section-title text-center">
                <h2>Create your cryptocurrency portfolio today</h2>
                <div className="text-16">
                  Caladex has a variety of features that make it the best place to start trading
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="portfolio_list">
                <div className="row gap-5">
                  <div className="col-xl-12">
                    <div className="d-flex align-items-center">
                      <div className="port-icon">
                        <i className="la la-bar-chart"></i>
                      </div>
                      <div className="flex-grow-1">
                        <h4>Manage your portfolio</h4>
                        <p>
                          Buy and sell popular digital currencies and real enterprise tokens. Keep track of them in one
                          platform
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="d-flex align-items-center">
                      <div className="port-icon">
                        <i className="la la-calendar-check-o"></i>
                      </div>
                      <div className="flex-grow-1">
                        <h4>Recurring buys</h4>
                        <p>Invest in cryptocurrency slowly over time by scheduling buys daily, weekly, or monthly.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="d-flex align-items-center">
                      <div className="port-icon">
                        <i className="la la-lock"></i>
                      </div>
                      <div className="flex-grow-1">
                        <h4>Global Funding</h4>
                        <p>Get your own project going by submitting your token using the token listing form.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="portfolio_img">
                <img src={require("./../../images/portfolio.png")} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-app section-padding">
        <div className="container">
          <div className="row pt-5">
            <div className="col-xl-12">
              <div className="trusted-business py-5 text-center">
                <h2>
                  Trusted by Our <strong>Partners & Investors</strong>
                </h2>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-auto">
                  <div className="trusted-logo">
                    <Link to={"#"}>
                      <img
                        className="img-fluid"
                        width={`300px`}
                        src={require("./../../images/landing/cryptoslate.webp")}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="trusted-logo">
                    <Link to={"#"}>
                      <img
                        className="img-fluid"
                        width={`300px`}
                        src={require("./../../images/landing/MoonPay.png")}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="trusted-logo">
                    <Link to={"#"}>
                      <img
                        className="img-fluid"
                        width={`300px`}
                        src={require("./../../images/landing/coincodex-logo.png")}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial section-padding" id="testimonial">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-title">
                <h2>What our customers say</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="testimonial-content">
                <Testimonial />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="promo section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="section-title text-center">
                <h2>The most trusted cryptocurrency platform</h2>
                <div className="text-18"> Here are a few reasons why you should choose Caladex</div>
              </div>
            </div>
          </div>
          <div className="row align-items-center py-5">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="promo-content">
                <div className="promo-content-img">
                  <img className="img-fluid" src={require("./../../images/icons/Secure-storage.png")} alt="" />
                </div>
                <h3>Secure storage </h3>
                <p>We store the vast majority of the digital assets in secure offline storage.</p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="promo-content">
                <div className="promo-content-img">
                  <img className="img-fluid" src={require("./../../images/icons/Wallets.png")} alt="" />
                </div>
                <h3>Whitelisted wallets</h3>
                <p>Keeping your funds safely locked up in smart contract</p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="promo-content">
                <div className="promo-content-img">
                  <img
                    className="img-fluid"
                    // src={require('./../../images/svg/finance.svg')}
                    src={require("./../../images/icons/Industry-Tokenization.png")}
                    alt=""
                  />
                </div>
                <h3>Industry tokenization</h3>
                <p>Tokenizing ideas and projects in a responsible way</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog section-padding" id="blog">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-title text-center">
                <Link to={`/blogs`}>
                  <h2>Blog</h2>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            {!isUndefined(newsList) &&
              newsList.map((news, index) => (
                <div className="col-xl-4 col-lg-4 col-md-12" key={`landing-blog-${index}`}>
                  <div className="blog-grid">
                    <div className="card">
                      <Link to={`/blogs/${news._id}`}>
                        <img className="img-fluid" src={`${BACKEND_FILE_URL}/${news.header_img}`} alt="" />
                        <div className="card-body">
                          <h4>{news.title}</h4>
                          <div className="text-14">{news.content?.slice(0, 30)}...</div>
                        </div>
                      </Link>
                      <div className="card-footer">
                        <div className="meta-info">
                          <Link to={"#"} className="author">
                            <img src={require("./../../images/logo-spin.png")} alt="" /> Admin
                          </Link>
                          <Link to={"#"} className="post-date">
                            <i className="la la-calendar"></i> {moment(news.created_at).format("d, MMMM, Y")}{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="get-touch section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-title">
                <h2>Get in touch. Stay in touch.</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="get-touch-content">
                <div className="d-flex">
                  <span>
                    <i className="fa fa-shield"></i>
                  </span>
                  <div className="flex-grow-1">
                    <h4>24 / 7 Support</h4>
                    <p>Got a problem? Just get in touch. Our support team is available 24/7.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="get-touch-content">
                <div className="d-flex">
                  <span>
                    <i className="fa fa-cubes"></i>
                  </span>
                  <div className="flex-grow-1">
                    <h4>Caladex Blog</h4>
                    <p>News and updates from the world’s coolest hybrid cryptocurrency exchange.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="get-touch-content">
                <div className="d-flex">
                  <span>
                    <i className="fa fa-certificate"></i>
                  </span>
                  <div className="flex-grow-1">
                    <h4>Careers</h4>
                    <p>Help build the future of technology. Start your new career at Caladex.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="get-touch-content">
                <div className="d-flex">
                  <span>
                    <i className="fa fa-life-ring"></i>
                  </span>
                  <div className="flex-grow-1">
                    <h4>Community</h4>
                    <p>Caladex is global. Join the discussion in our worldwide communities.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bottom />

      <Footer1 />
    </>
  );
}

export default Homepage;
