import React, { useContext, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../element/page-title";
import Footer2 from "../layout/footer2";
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from "../layout/header2";
import SettingsSubmenu from "../layout/settings-submenu";
import Sidebar from "../layout/sidebar";
import { useMyInfo } from "../../state/hooks";
import { AuthContext } from "../../contexts/AuthContext";
import { isEmpty, isUndefined } from "lodash";
import moment from "moment";
import * as Yup from "yup";
import { BACKEND_API_URL, HTTP_SUCCESS_STATUS, USER_STATUS } from "../../shared/constants";
import { toast } from "react-hot-toast";
import Axios from "axios";
import { isPhoneNumber } from "../../shared/helpers";
import { Button } from "react-bootstrap";

function Security() {
  const history = useHistory();

  const { currentUser, authToken, isAuthenticated } = useContext(AuthContext);

  const [isEmailChanging, setEmailChanging] = useState(false);
  const [isPhoneChanging, setPhoneChanging] = useState(false);

  const emailRef = useRef();
  const phoneRef = useRef();

  const { data, refetch: userInfoRefetch, isRefetching: userInfoRefetching } = useMyInfo(authToken, currentUser?.id);

  const handleTwoFAChange = async () => {
    if (!isAuthenticated || !data?.user || isEmpty(currentUser) || !authToken) {
      toast.error("Please login to your account.");
      return false;
    }

    const payloadSchema = Yup.object().shape({
      status: Yup.boolean().required(),
    });

    let validationResult = "";

    await payloadSchema.validate({ status: !data?.user?.two_fa_enabled }).catch((err) => {
      validationResult = err.message;
      return false;
    });

    if (validationResult) {
      console.log(validationResult);
      toast.error("Invalid parameter detected.");
      return false;
    }

    const _toast = toast.loading("Resending Email...");

    try {
      let response = await Axios.post(
        `${BACKEND_API_URL}/user/two-fa-status`,
        { status: !data?.user?.two_fa_enabled },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response?.status !== HTTP_SUCCESS_STATUS) {
        toast.dismiss(_toast);
        toast.error("Unfortunately status not changed!");
      } else {
        toast.success("Status Successfully Changed!");
        userInfoRefetch();
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        console.log("Settings-Security@change-2fa:", e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        toast.error(e.message);
        console.log("Settings-Security@change-2fa:", e?.message);
      }
    } finally {
      toast.dismiss(_toast);
    }
    return false;
  };

  const handleEmailChange = async () => {
    if (
      !isAuthenticated ||
      isEmpty(currentUser) ||
      !authToken ||
      currentUser.status == USER_STATUS.VERIFYING ||
      currentUser.status == USER_STATUS.VERIFIED
    ) {
      toast.error("Please login or your account is verified already.");
      return false;
    }

    if (
      !data?.user?.password_last_verified_at ||
      moment(data?.user?.password_last_verified_at).valueOf() < moment().subtract(1, "days").valueOf()
    ) {
      history.push("/confirm-password?from=settings");
      return false;
    }

    const email = emailRef.current.value;

    const emailValidationSchema = Yup.string()
      .email("Please input valid email address")
      .max(255, "Email address should be shorter than 255.")
      .required("Please input Email address.");

    let validationResult = "";
    await emailValidationSchema.validate(email).catch((err) => {
      validationResult = err.message;
      return false;
    });

    if (validationResult) {
      toast.error(validationResult);
      return false;
    }

    const _toast = toast.loading("Changing Email...");
    setEmailChanging(true);

    try {
      const emailChangeResult = await Axios.post(
        `${BACKEND_API_URL}/user/change-email`,
        { email },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (emailChangeResult.status != HTTP_SUCCESS_STATUS) {
        toast.dismiss(_toast);
        console.log("Security-Page@change-email-error:", emailChangeResult?.data?.message);
        setEmailChanging(false);
        return false;
      }

      toast.dismiss(_toast);
      toast.success("Email successfully updated!");
      history.push(`/verify-email/${currentUser?.id}`);
    } catch (e) {
      console.log("email-change", e);
      if (e?.response?.data?.message) {
        console.log("Security-Page@change-email-error:", e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        console.error("Security-Page@change-email-error:", e.message);
        toast.error(e.message);
      }
    }

    setEmailChanging(false);
    return false;
  };

  const handlePhoneChange = async () => {
    if (
      !isAuthenticated ||
      isEmpty(currentUser) ||
      !authToken ||
      currentUser.status == USER_STATUS.VERIFYING ||
      currentUser.status == USER_STATUS.VERIFIED
    ) {
      toast.error("Please login or your account is verified already.");
      return false;
    }

    if (
      !data?.user?.password_last_verified_at ||
      moment(data?.user?.password_last_verified_at).valueOf() < moment().subtract(1, "days").valueOf()
    ) {
      history.push("/confirm-password?from=settings");
      return false;
    }

    const phone = phoneRef.current.value;

    const phoneValidationSchema = Yup.string()
      .min(3, "Please input valid phone number")
      .max(255, "phone number should be shorter than 255.")
      .required("Please input phone number.");

    let validationResult = "";

    await phoneValidationSchema.validate(phone).catch((err) => {
      validationResult = err.message;
      return false;
    });

    if (!isPhoneNumber(phone)) {
      validationResult = "Please input valid phone number!";
    }

    if (validationResult) {
      toast.error(validationResult);
      return false;
    }

    const _toast = toast.loading("Changing Phone Number...");
    setPhoneChanging(true);

    try {
      const phoneChangeResult = await Axios.post(
        `${BACKEND_API_URL}/user/change-phone-number`,
        { phone: isPhoneNumber(phone) },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (phoneChangeResult.status != HTTP_SUCCESS_STATUS) {
        toast.dismiss(_toast);
        toast.error("Unexpected Error Occured!");
        setPhoneChanging(false);
        return false;
      }

      toast.dismiss(_toast);
      toast.success("Phone number successfully updated!");
      // history.push(`/otp-2`);
    } catch (e) {
      toast.dismiss(_toast);
      if (e?.response?.data?.message) {
        console.log("Security-Page@change-phone-number-error:", e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        console.error("Security-Page@change-phone-number-error:", e.message);
        toast.error(e.message);
      }
    }

    setPhoneChanging(false);
    return false;
  };

  return (
    <>
      <Header2 />
      <PageTitle />

      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <SettingsSubmenu activePage={`security`} data={data} />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Security</h4>
                </div>
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-xl-4">
                      <div className="id_card">
                        <img src={require("../../images/id.png")} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="id_info">
                        <h4>{!isEmpty(data) && data?.user?.name ? data?.user?.name : ` - `} </h4>
                        <p className="mb-1 mt-3">
                          ID: {!isEmpty(data) && data?.user?.id_serial_number ? data?.user?.id_serial_number : ` - `}{" "}
                        </p>
                        <p className="mb-1">
                          Status:{" "}
                          <span
                            className={`font-weight-bold ${
                              !isEmpty(data) && data?.user?.id_verified_at ? `text-primary` : `text-danger`
                            }`}
                          >
                            {!isEmpty(data) && data?.user?.id_verified_at
                              ? "Uploaded At " + moment(data?.user?.id_verified_at).format("DD MMMM, YYYY, hh:mm:ss")
                              : `unverified`}
                          </span>
                        </p>
                        <Link
                          to={`/verify-step-2`}
                          className="btn btn-success mt-3"
                          disabled={
                            isUndefined(data) ||
                            data?.user?.status === USER_STATUS.VERIFYING ||
                            data?.user?.status === USER_STATUS.VERIFIED
                          }
                        >
                          New ID
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <div className="phone_verify">
                        <h4 className="card-title mb-3">Email Address</h4>
                        <div className="row align-items-center">
                          <div className="mb-3 col-xl-5">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="hello@example.com "
                                ref={emailRef}
                                disabled={
                                  isUndefined(data) ||
                                  data?.user?.status === USER_STATUS.VERIFYING ||
                                  data?.user?.status === USER_STATUS.VERIFIED
                                }
                              />
                              <div className="input-group-append">
                                {!isEmailChanging ? (
                                  <button
                                    className=" btn input-group-text bg-primary text-white"
                                    onClick={handleEmailChange}
                                  >
                                    Add
                                  </button>
                                ) : (
                                  <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="phone_verified">
                        <h5>
                          {" "}
                          <span>
                            <i className="fa fa-envelope"></i>
                          </span>{" "}
                          {!isEmpty(data) && data?.user?.email ? data?.user?.email : ` - `}
                        </h5>
                        <div className="verify">
                          <div className={!isEmpty(data) && data?.user?.email_verified_at ? `verified` : `not-verify`}>
                            <span>
                              <i
                                className={`la ${
                                  !isEmpty(data) && data?.user?.email_verified_at ? `la-check` : `la-clock`
                                }`}
                              ></i>
                            </span>
                            <Link to={"#"}>
                              {!isEmpty(data) && data?.user?.email_verified_at
                                ? moment(data?.user?.email_verified_at).format("DD MMMM, YYYY, hh:mm:ss")
                                : `Unverified`}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="phone_verify">
                        <h4 className="card-title mb-3">Add Phone Number</h4>
                        <div className="row align-items-center">
                          <div className="mb-3 col-xl-5">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="+1 2335 2458 "
                                ref={phoneRef}
                                disabled={
                                  isUndefined(data) ||
                                  data?.user?.status === USER_STATUS.VERIFYING ||
                                  data?.user?.status === USER_STATUS.VERIFIED
                                }
                              />
                              <div className="input-group-append">
                                {!isPhoneChanging ? (
                                  <button
                                    className=" btn input-group-text bg-primary text-white"
                                    onClick={handlePhoneChange}
                                  >
                                    Add
                                  </button>
                                ) : (
                                  <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="phone_verified">
                        <h5>
                          {" "}
                          <span>
                            <i className="fa fa-phone"></i>
                          </span>{" "}
                          +{!isEmpty(data) && data?.user?.phone_number ? data?.user?.phone_number : ` - `}
                        </h5>
                        <div className="verify">
                          <div className={!isEmpty(data) && data?.user?.phone_verified_at ? `verified` : `not-verify`}>
                            <span>
                              <i
                                className={`la ${
                                  !isEmpty(data) && data?.user?.phone_verified_at ? `la-check` : `la-clock`
                                }`}
                              ></i>
                            </span>
                            <Link to={"#"}>
                              {!isEmpty(data) && data?.user?.phone_verified_at
                                ? moment(data?.user?.phone_verified_at).format("DD MMMM, YYYY, hh:mm:ss")
                                : `Unverified`}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="phone_verify">
                        <h4 className="card-title mb-3">Enable/Disable Two Factor Authentication</h4>
                        <div className="row align-items-center">
                          <div className="mb-3 col-xl-5">
                            {!isEmpty(data) && data?.user?.two_fa_enabled ? (
                              <Button type="button" className="btn btn-danger" onClick={handleTwoFAChange}>
                                Disable 2FA Authentication
                              </Button>
                            ) : (
                              <Button type="button" className="btn btn-primary" onClick={handleTwoFAChange}>
                                Enable 2FA Authentication
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default Security;
