import React from "react";
import { Link } from "react-router-dom";

function Footer1() {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="copyright">
              <p>
                © Copyright {new Date().getFullYear()} <Link to={"/"}> Caladex </Link> I All Rights Reserved
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="footer-social">
              <ul>
                <li>
                  <Link to={{ pathname: "https://www.facebook.com/caladexorg" }} target={`_blank`}>
                    <i className="fa fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: "https://twitter.com/caladexorg" }} target={`_blank`}>
                    <i className="fa fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: "https://www.linkedin.com/in/antony-kamermans/" }} target={`_blank`}>
                    <i className="fa fa-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: "https://www.youtube.com/@caladexorg" }} target={`_blank`}>
                    <i className="fa fa-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer1;
