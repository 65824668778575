import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isEmpty, isUndefined } from "lodash";
import Axios from "axios";
import {
  erc20ABI,
  useAccount,
  useBlockNumber,
  useContractWrite,
  useFeeData,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { waitForTransaction } from "@wagmi/core";
import toast from "react-hot-toast";
import Web3 from "web3";
import { CircularProgress } from "@mui/material";

import PageTitle from "../element/page-title";
import AccountSubmenu from "../layout/account-submenu";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import { AuthContext } from "../../contexts/AuthContext";
import { atomTargetTokenList, atomTokenPairList, atomWalletTokenBalance } from "../../state/atoms";
import {
  BACKEND_API_URL,
  BACKEND_FILE_URL,
  CALADEX_ADDRESSES,
  CHAIN_LIST,
  DEFAULT_TOKEN_SYMBOL,
  DEPOSIT_SIGNATURE,
  HTTP_CLIENT_ERROR_STATUS,
  HTTP_NO_CONTENT_STATUS,
} from "../../shared/constants";
import { useAccountInfo, useWalletTokenBalance } from "../../state/hooks";
import { CALADEXABI, getContractArgFigure } from "../../shared/web3";
import { formatDegits, removeDuplicatedTokens } from "../../shared/helpers";
import SearchDropdown from "../element/search-dropdown";

function AccountDeposit() {
  const { id } = useParams();
  const history = useHistory();
  const { currentUser, authToken, tokenPairRefresh } = useContext(AuthContext);
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data: feeData, isLoading: isFeeLoading, refetch: feeRefetch, isFetched } = useFeeData();
  const tokenPairList = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList);
  const walletBalances = useRecoilValue(atomWalletTokenBalance);
  const { isRefetching: isWalletBalanceRefetching, refetch: walletBalanceRefetch } = useWalletTokenBalance();
  const { data, refetch: userInfoRefetch } = useAccountInfo(authToken, currentUser?.id);
  const { isLoading, switchNetwork } = useSwitchNetwork();
  const { refetch: blockNumberRefetch } = useBlockNumber({
    chainId: chain?.id,
    enabled: false,
  });

  const depositInputRef = useRef();

  const [caladexAddress, setCaladexAddress] = useState(CALADEX_ADDRESSES["0x1"]);
  const [isDepositing, setIsdepositing] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [depositAmount, setDepositAmount] = useState();
  const [tokenList, setTokenList] = useState();

  useEffect(() => {
    const tokens = [...removeDuplicatedTokens(tokenPairList), ...targetTokenList]?.map((token) => ({
      id: token._id,
      tokenSymbol: token.symbol,
      chainName: CHAIN_LIST.find((chain) => chain.chainId === token.chain_id).chainName,
    }));
    setTokenList(tokens);
  }, [tokenPairList, targetTokenList]);

  const getTokenInfo = () => {
    let token = {};
    if (isUndefined(id)) {
      token = [...tokenPairList, ...targetTokenList].find(
        (_token) => _token.chain_id == CHAIN_LIST[1]?.chainId && _token.symbol == DEFAULT_TOKEN_SYMBOL
      );
    } else {
      token = [...tokenPairList, ...targetTokenList].find((_token) => _token?.token_id == id || _token?._id == id);
    }

    return token;
  };

  const { writeAsync: approveWrite } = useContractWrite({
    chainId: chain?.id,
    address: getTokenInfo()?.address,
    abi: erc20ABI,
    functionName: "approve",
    enabled: false,
  });

  const { writeAsync: depositWrite } = useContractWrite({
    chainId: chain?.id,
    address: caladexAddress,
    abi: CALADEXABI,
    functionName: "deposit",
    enabled: false,
  });

  useEffect(() => {
    tokenPairRefresh().then(() => {
      setInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (isInitialized) walletBalanceRefetch();
  }, [isConnected, address, isInitialized]);

  useEffect(() => {
    if (!isEmpty(tokenPairList) && !isEmpty(targetTokenList) && switchNetwork) {
      const chainId = getTokenInfo().chain_id?.toString(10);
      switchNetwork?.(chainId);
    }
  }, [isInitialized, switchNetwork]);

  useEffect(() => {
    feeRefetch();
    if (isConnected) {
      const currentChainId = Web3.utils.toHex(chain?.id);
      setCaladexAddress(CALADEX_ADDRESSES[currentChainId] ?? CALADEX_ADDRESSES["0x1"]);
    }
  }, [chain]);

  const handleDeposit = async (e) => {
    e.preventDefault();

    const token = getTokenInfo();

    if (!isConnected || Web3.utils.toHex(chain.id) != token?.chain_id) {
      toast.error("Please connect your wallet or switch to network.");
      return false;
    }

    if (isEmpty(currentUser) || !authToken) {
      toast.error("Please sign in to depsit token.");
      return false;
    }

    const tokenBalance = +getTokenBalance();
    const amountToDeposit = +depositAmount;

    if (!amountToDeposit || !tokenBalance || amountToDeposit > tokenBalance) {
      toast.error("Please check token balance or input correct token amount.");
      depositInputRef.current.focus();
      return false;
    }

    const _toast = toast.loading("Depositing token...");
    setIsdepositing(true);

    try {
      const { data: blockNumberData } = await blockNumberRefetch();

      // send approve request
      const { hash: approveHash } = await approveWrite({
        args: [caladexAddress, getContractArgFigure(depositAmount, token.decimal)],
        from: address,
      });

      await waitForTransaction({
        confirmations: 1,
        hash: approveHash,
      });

      // send token transfer request
      const { hash: transactionHash } = await depositWrite({
        args: [token.address, getContractArgFigure(depositAmount, token.decimal)],
        from: address,
      });

      const txData = await waitForTransaction({
        confirmations: 1,
        hash: transactionHash,
      });

      console.log({ txData });
      console.log("Transaction successful:", transactionHash);

      // send server request
      const response = await Axios.post(
        `${BACKEND_API_URL}/balance/deposit`,
        {
          blockNumber: parseInt(blockNumberData),
          transactionHash,
          signature: DEPOSIT_SIGNATURE,
          chainId: token?.chain_id,
          tokenAddress: token?.address,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.status === HTTP_NO_CONTENT_STATUS) {
        throw new Error("No Token Found!");
      }

      toast.dismiss(_toast);
      toast.success("Token Successfully Deposited!");
      userInfoRefetch();

      setTimeout(() => {
        history.push("/account-overview");
      }, 1000);
    } catch (e) {
      toast.dismiss(_toast);

      // if blockchain network not found the tx
      if (e?.response?.status === HTTP_CLIENT_ERROR_STATUS) {
        toast.error("Cannot find deposit transaction. Please wait for a few sec to verify transaction.");
        setTimeout(() => {
          history.push("/account-overview");
        }, 1000);
      } else if (e?.response?.data?.message) {
        console.log("Deposit-Page@deposit-error:", e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        console.error("Deposit-Page@deposit-error:", e.message);
        toast.error(e.message);
      }
    } finally {
      setIsdepositing(false);
      walletBalanceRefetch();
    }
    return false;
  };

  const getTokenBalance = () => {
    const token = getTokenInfo();
    const balance = walletBalances.find(
      (balance) => balance.token_id == token?._id || balance.token_id == token?.token_id
    );

    return balance?.balance ?? 0;
  };

  const getTokenTitle = () => {
    return (
      <div>
        {!isEmpty(tokenPairList) && !isEmpty(targetTokenList) ? getTokenInfo()?.symbol : ` - `} (
        {!isEmpty(tokenPairList)
          ? CHAIN_LIST.find((chain) => chain.chainId == getTokenInfo()?.chain_id)?.chainName
          : ` - `}
        )
      </div>
    );
  };

  return (
    <>
      <Header2 />
      <PageTitle />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <AccountSubmenu active={`deposit`} />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-white-80">Wallet Deposit </h4>
                  <SearchDropdown title={getTokenTitle()} options={tokenList} type="deposit" />
                </div>
                <div className="card-body col-xl-10 mx-auto my-5" id="deposits">
                  <div className="qrcode mx-auto">
                    {!isEmpty(tokenPairList) && !isEmpty(targetTokenList) ? (
                      <img
                        src={`${BACKEND_FILE_URL}/${getTokenInfo()?.logo_url}`}
                        alt=""
                        width="150"
                        className="d-block mx-auto"
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  <form className="py-5  deposit-form">
                    <div className="mb-3 row align-items-center">
                      <div className="col-sm-4">
                        <label htmlFor="inputEmail3" className="col-form-label">
                          Deposit Amount
                          <br />
                          <small className={!!+getTokenBalance() ? `text-primary` : `text-danger`}>
                            Available Amount:{" "}
                            {!isEmpty(walletBalances) && !isWalletBalanceRefetching ? (
                              getTokenBalance()
                            ) : (
                              <CircularProgress
                                sx={{
                                  width: "0.8rem !important",
                                  height: "0.8rem !important",
                                }}
                              />
                            )}{" "}
                            {!isEmpty(tokenPairList) && !isEmpty(targetTokenList) ? getTokenInfo()?.symbol : ` - `}
                          </small>
                        </label>
                      </div>
                      <div className="col-sm-8">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label className="input-group-text  bg-primary">
                              <i className="mdi mdi-currency-usd fs-18 text-white"></i>
                            </label>
                          </div>
                          <input
                            type="number"
                            className={`form-control text-end ${
                              depositAmount > +getTokenBalance() ? `is-invalid` : ``
                            }`}
                            placeholder="0"
                            value={depositAmount}
                            onChange={(e) => {
                              setDepositAmount(e.target.value);
                            }}
                            ref={depositInputRef}
                          />
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text fs-18 bg-info text-white cursor-pointer"
                              onClick={() => {
                                setDepositAmount(getTokenBalance());
                              }}
                            >
                              Max
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 row align-items-center">
                      <div className="col-sm-6">
                        <label htmlFor="inputEmail3" className="col-form-label">
                          {isConnected && !isEmpty(tokenPairList) && !isEmpty(targetTokenList) ? chain?.name : ` - `}{" "}
                          Network Fee (
                          {isConnected && !isEmpty(tokenPairList) && !isEmpty(targetTokenList)
                            ? chain?.nativeCurrency.symbol
                            : ` - `}
                          )
                          <br />
                          <small>
                            Transactions on the{" "}
                            {isConnected && !isEmpty(tokenPairList) && !isEmpty(targetTokenList) ? chain?.name : ` - `}{" "}
                            network are priorirized by fees
                          </small>
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <h4 className="text-end">
                          {isConnected &&
                            isFetched &&
                            formatDegits(Web3.utils.fromWei(feeData.gasPrice?.toString(), "gwei"), 2)}
                          Gwei
                        </h4>
                      </div>
                    </div>

                    <div className="text-end">
                      {!isDepositing ? (
                        <button
                          className="btn btn-primary"
                          disabled={
                            !approveWrite || !+getTokenBalance() || !depositAmount || depositAmount > +getTokenBalance()
                          }
                          onClick={(e) => {
                            handleDeposit(e);
                          }}
                        >
                          Deposit Now
                        </button>
                      ) : (
                        <CircularProgress />
                      )}
                      {/* <button className="btn btn-primary" onClick={() => { handleDeposit() }}>Deposit Now</button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default AccountDeposit;
