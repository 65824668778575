import Web3 from "web3";
import BigNumber from "bignumber.js";
import { isNumber } from "lodash";
import { toFixed } from "./helpers";

// const CoinbaseWallet = new WalletLinkConnector({
//   url: `https://mainnet.infura.io/v3/${REACT_APP_INFURA_KEY}`,
//   appName: "Caladex Exchange",
//   supportedChainIds: [1, 56, 97, 137],
// });

// const WalletConnect1 = new WalletConnect({
//   defaultChainId: 1,
//   options: {
//     chains: [1, 56, 137],
//     projectId: '3be744a75b83b0910fdffff5cafc7d71',
//     // rpc: {
//     //   1: `https://mainnet.infura.io/v3/${REACT_APP_INFURA_KEY}`,
//     //   56: `https://bsc-dataseed1.binance.org`,
//     //   137: `https://matic-mainnet.chainstacklabs.com`,
//     // },
//     // appName: 'Caladex Exchange',
//     // supportedChainIds: [1, 56, 97, 137],
//     // bridge: 'https://bridge.walletconnect.org',
//     // qrcode: true,
//     // pollingInterval: 15000,
//     rpcMap: {
//       1: `https://mainnet.infura.io/v3/${REACT_APP_INFURA_KEY}`,
//       56: `https://bsc-dataseed1.binance.org`,
//       137: `https://matic-mainnet.chainstacklabs.com`,
//     },
//     showQrModal: true,
//   },
//   actions: {
//     startActivation: () => {
//       console.log('starting activation');
//       return () => {
//         console.log('canceling activation');
//       };
//     },
//     update: (stateUpdate) => {
//       console.log(stateUpdate);
//     },
//     resetState: () => {
//       console.log('reset state');
//     },
//   },
//   onError: (error) => {
//     console.log(error.message);
//     return false;
//   },
// });

// const injected = new InjectedConnector({ supportedNetworks: [1, 56, 97, 137] });

const web3 = new Web3(Web3.givenProvider);

const ERC20ABI = require("./abi/erc20.json");
export const CALADEXABI = require("./abi/caladex.json");
export const FARMINGABI = require("./abi/caladex-farming.json");

// export const walletConnect = async (activate, option) => {
//   try {
//     let connector = injected;
//     switch (option) {
//       case WALLET_OPTIONS.WALLETCONNECT:
//         // connector = WalletConnect;
//         break;
//       case WALLET_OPTIONS.COINBASE:
//         connector = CoinbaseWallet;
//         break;
//       default:
//         connector = injected;
//         break;
//     }
//     // activate(connector);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const walletDisconnect = async (deactivate, option) => {
  try {
    deactivate();
  } catch (error) {
    console.log(error);
  }
};

export const humanReadableAccount = (_account) => {
  return _account.slice(0, 6) + "..." + _account.slice(_account.length - 4);
};

/**
 *
 * @param {string} _account wallet address
 * @param {string} _address contract address
 * @param {Web3} _web3 we3 instance for getting balance
 * @returns balance of certain token in certain network
 */
export const getTokenBalance = async (_account, _address, _web3, _decimal) => {
  try {
    const tokenContract = new _web3.eth.Contract(ERC20ABI, _address);
    let balance = await tokenContract.methods.balanceOf(_account).call();
    return new BigNumber(balance).shiftedBy(-_decimal).toFixed(3);
  } catch (error) {
    console.error("Error fetching balance:", error);
    return 0.0; // or handle the error as needed
  }
};

export const getChainPoolList = async (_farmingAddress, _web3) => {
  const farmingContract = new _web3.eth.Contract(FARMINGABI, _farmingAddress);

  let poolList = [];

  try {
    const poolLength = await farmingContract.methods.poolLength().call();

    if (isNumber(+poolLength)) {
      poolList = await Promise.all(
        Array(+poolLength)
          .fill(1)
          .map(async (value, index) => {
            const poolInfo = await farmingContract.methods.poolInfo(index).call();
            const harvestFee = await farmingContract.methods.harvestFee().call();
            return { index: index, poolInfo, harvestFee: harvestFee };
          })
      );
    }
  } catch (e) {
    console.log("Error-getChainPoolList:", e);
  }

  return poolList;
};

export const getPoolUserInfoList = async (_account, _index, _farmingAddress, _web3, decimal) => {
  const farmingContract = new _web3.eth.Contract(FARMINGABI, _farmingAddress);

  let poolList = [];

  try {
    const userInfo = await farmingContract.methods.userInfo(_index, _account).call();

    return { index: _index, userInfo };
  } catch (e) {
    console.log("Error-getChainPoolList:", e);
  }

  return poolList;
};

export const getUserUnstakeFee = async (_account, _index, _farmingAddress, _web3) => {
  const farmingContract = new _web3.eth.Contract(FARMINGABI, _farmingAddress);

  let poolList = [];

  try {
    const unstakeFee = await farmingContract.methods.getUnStakeFeePercent(_index, _account).call();

    return { index: _index, unstakeFee };
  } catch (e) {
    console.log("Error-getUserUnstakeFee:", e);
  }

  return poolList;
};

export const stakeToken = async (_account, _amount, _index, _farmingAddress, _decimal) => {
  const farmingContract = new web3.eth.Contract(FARMINGABI, _farmingAddress);

  try {
    const stakeAmount = new BigNumber(_amount).shiftedBy(+_decimal).toString();
    await farmingContract.methods.stake(_index, toFixed(stakeAmount)).send({ from: _account });
  } catch (e) {
    console.log("Error-getUserUnstakeFee:", e);
  }

  return false;
};

export const unstakeToken = async (_account, _amount, _index, _farmingAddress, _decimal) => {
  const farmingContract = new web3.eth.Contract(FARMINGABI, _farmingAddress);

  try {
    const unstakeAmount = new BigNumber(_amount).shiftedBy(+_decimal).toString();
    await farmingContract.methods.unStake(_index, toFixed(unstakeAmount)).send({ from: _account });
  } catch (e) {
    console.log("Error-unstakeToken:", e);
  }

  return false;
};

export const harvestToken = async (_account, _index, _farmingAddress, _decimal) => {
  const farmingContract = new web3.eth.Contract(FARMINGABI, _farmingAddress);

  try {
    await farmingContract.methods.harvest(_index, 0).send({ from: _account });
  } catch (e) {
    console.log("Error-unstakeToken:", e);
  }

  return false;
};

export const getUserReward = async (_account, _index, _farmingAddress, _web3) => {
  const farmingContract = new _web3.eth.Contract(FARMINGABI, _farmingAddress);

  let poolList = [];

  try {
    const pendingAmomunt = await farmingContract.methods.getPending(_index, _account).call();

    return { index: _index, pendingAmomunt };
  } catch (e) {
    console.log("Error-getChainPoolList:", e);
  }

  return poolList;
};

export const getTokenInfo = async (_contractAddress, _web3) => {
  let _tokenInfo = {};
  try {
    const tokenContract = new _web3.eth.Contract(ERC20ABI, _contractAddress);

    let name = await tokenContract.methods.name().call();
    let symbol = await tokenContract.methods.symbol().call();
    let decimal = await tokenContract.methods.decimals().call();

    _tokenInfo = { name, symbol, decimal };
  } catch (e) {
    console.log("Error-getTokenInfo:", e);
  }

  return _tokenInfo;
};

export const approveToken = async (_account, _address, _caladex_address, _amount, _decimal) => {
  const tokenContract = new web3.eth.Contract(ERC20ABI, _address);
  const approveAmount = new BigNumber(_amount).shiftedBy(+_decimal).toString();
  const result = await tokenContract.methods.approve(_caladex_address, toFixed(approveAmount)).send({ from: _account });

  return result;
};

export const depositToken = async (_account, _address, _caladex_address, _amount, _decimal) => {
  const caladexContract = new web3.eth.Contract(CALADEXABI, _caladex_address);
  const transferAmount = new BigNumber(_amount).shiftedBy(+_decimal).toString();

  const result = await caladexContract.methods.deposit(_address, toFixed(transferAmount)).send({ from: _account });

  return result;
};

export const getContractArgFigure = (_amount, _decimal) => {
  return toFixed(new BigNumber(_amount).shiftedBy(+_decimal).toString());
};

export const getGasPrice = async () => {
  let gasPrice = undefined;
  await web3.eth.getGasPrice((err, gas) => {
    if (!err) {
      gasPrice = Web3.utils.fromWei(gas, "gwei");
    }
  });

  return gasPrice;
};
