import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <h4 className="d-flex align-items-center gap-1">
      {children}
      <span className="text-14 mt-1">&#x25bc;</span>
    </h4>
  </a>
));

const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  const [value, setValue] = useState("");

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <Form.Control
        autoFocus
        className="mx-2 my-1 w-auto text-14 py-2"
        placeholder="Type to filter..."
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <ul className="list-unstyled" style={{ height: 300, overflowY: "auto" }}>
        {React.Children.toArray(children).filter(
          (child) => !value || child.props.children[0].toLowerCase().includes(value.toLowerCase())
        )}
      </ul>
    </div>
  );
});

const SearchDropdown = ({ title, options, type }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {options?.map((option, index) => (
          <Dropdown.Item eventKey={index} to={`/account-${type}/${option.id}`} as={Link} key={index}>
            {option.tokenSymbol} ({option.chainName})
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchDropdown;
