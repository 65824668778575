import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { useTradingViewData } from "../../state/hooks";
import { useState } from "react";
import { useEffect } from "react";
import { atomTokenPairList } from "../../state/atoms";
import { useRecoilValue } from "recoil";
import { isEmpty } from "lodash";
import Axios from "axios";
import { BACKEND_API_URL } from "../../shared/constants";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { getXAxisTime } from "./area";

const LandingChart = (props) => {
  const { chainId, targetTokenSymbol, pairTokenSymbol } = props;

  const tokenPairList = useRecoilValue(atomTokenPairList);

  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    chart: {
      height: 100,
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },

      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: ["#F7931A"],
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
      x: {
        format: "dd MMM yyyy",
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },

      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    min: moment().subtract(30, "days").valueOf(),
    max: new Date().getTime(),
  });

  const [series, setSeries] = useState([
    {
      name: "Desktops",
      data: [],
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (isEmpty(tokenPairList)) {
        return false;
      }
      setLoading(true);
      const token = tokenPairList.find(
        (token) => token.symbol === targetTokenSymbol?.toUpperCase() && token.chain_id === chainId
      );

      const response = await Axios.post(`${BACKEND_API_URL}/tradingview/get`, {
        token_id: token?.token_id,
        pair_token: pairTokenSymbol,
      });

      const { data } = response;

      setSeries(getCompatibleData(data?.data?.data?.information ?? []));
      setOptions((prev) => ({
        ...prev,
        min: getXAxisTime(data?.data?.data?.information ?? [])[0] + 60000,
        max: getXAxisTime(data?.data?.data?.information ?? [])[1] + 60000,
      }));
      setLoading(false);

      return false;
    };

    fetchData();
  }, []);

  return !isLoading ? (
    <ReactApexChart options={options} series={series} type="line" height={80} />
  ) : (
    <div className="d-flex justify-content-center align-items-center" style={{ width: "250px", height: "80px" }}>
      <CircularProgress />
    </div>
  );
};

const getCompatibleData = (_tradingViewData) => {
  let compatibleData = [];

  _tradingViewData.map((data, index) => {
    const xData = new Date(data.x).getTime();
    compatibleData.push([xData, +data.y[0].toFixed(6)]);
  });
  return [{ name: "Desktop", data: compatibleData }];
};

export default LandingChart;
