import { isUndefined, trim } from "lodash";
import * as Yup from "yup";
import { BLOCK_VERIFY_TIMES } from "./constants";
import BigNumber from "bignumber.js";

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

/** Used to map characters to HTML entities. */
const htmlEscapes = {
  "&": "&amp",
  "<": "&lt",
  ">": "&gt",
  '"': "&quot",
  "'": "&#39",
};

/** Used to match HTML entities and HTML characters. */
const reUnescapedHtml = /[&<>"']/g;
const reHasUnescapedHtml = RegExp(reUnescapedHtml.source);

export function escape(string) {
  return string && reHasUnescapedHtml.test(string)
    ? string.replace(reUnescapedHtml, (chr) => htmlEscapes[chr])
    : string;
}

export function escapeHtmlObject(unsafe) {
  for (let _item in unsafe) {
    // if (_item === "password") {
    //     continue
    // }
    unsafe[_item] = escape(unsafe[_item]);
  }
  return unsafe;
}

// export const setAuthToken = (_token) => {
//     return localStorage.setItem('@auth-token', _token)
// }

// export const getAuthToken = () => {

//     const _token = localStorage.getItem('@auth-token')
//     return _token !== null ? `Bearer ${_token}` : ""
// }

export const getRefreshToken = (_token) => {
  return localStorage.getItem("@refresh-token");
};

export const setRefreshToken = (_token) => {
  return localStorage.setItem("@refresh-token", _token);
};

export function isPhoneNumber(inputtxt) {
  const phoneno = /^\+?([0-9]+)?\s?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/;
  if (inputtxt.match(phoneno)) {
    return inputtxt.match(phoneno).slice(1, 6).join("");
  } else {
    return false;
  }
}

export const getTradingPairName = (targetSymbol, baseSymbol) => {
  return `${targetSymbol}_${baseSymbol}`.toLowerCase();
};

export const getSymbolsFromTradingPair = (tradingPair) => {
  const symbols = trim(tradingPair).split("_");
  const targetSymbol = symbols[0];
  const baseSymbol = symbols[1];

  return [targetSymbol, baseSymbol];
};

export const formatDegits = (_targetNumber, _digits) => {
  if (isNaN(_targetNumber)) {
    return undefined;
  }
  return Intl.NumberFormat("en", { maximumFractionDigits: _digits }).format(_targetNumber);
};

export const formatDegits1 = (_targetNumber, _digits) => {
  if (isNaN(_targetNumber)) {
    return undefined;
  }
  return parseFloat(_targetNumber.toFixed(_digits));
};

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};

export const isValidFileType = (fileName, fileType) => {
  return fileName && validFileExtensions[fileType?.toLowerCase()].indexOf(fileName.split(".").pop()) > -1;
};

// validation part

export const idValidationSchema = Yup.string()
  .min(10, "Please input valid id.")
  .max(255, "Please input valid id.")
  .required("Please input valid id.");

export const getAPY = (poolData, chainId) => {
  const blockTime = isUndefined(BLOCK_VERIFY_TIMES[chainId]) ? BLOCK_VERIFY_TIMES["0x1"] : BLOCK_VERIFY_TIMES[chainId];
  const { rewardPerBlock, totalStakedAmount } = poolData;

  return new BigNumber(totalStakedAmount).isEqualTo(0)
    ? " Be the first to earn! "
    : new BigNumber(rewardPerBlock)
        .multipliedBy((3600 / blockTime) * 24 * 365)
        .div(new BigNumber(totalStakedAmount))
        .multipliedBy(100)
        .toFixed(2) + "%";
};

export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1).sort();
}

export const formatPrecision = (num) => {
  const parsedNum = parseFloat(num);

  if (isNaN(parsedNum) || parsedNum === 0) return 0;

  let precision = 0;
  if (Math.abs(parsedNum) < 1) {
    precision = Math.max(0, -Math.floor(Math.log10(Math.abs(parsedNum))) + 5);
  } else {
    precision = 2;
  }

  return Number(parsedNum.toFixed(precision));
};

export const removeDuplicatedTokens = (tokens) => {
  const filteredTokens = [];
  tokens.map((value, index, array) => {
    if (filteredTokens.find((token) => token.token_id === value.token_id)) {
      return false;
    }
    filteredTokens.push(value);
    return true;
  });

  return filteredTokens;
};
