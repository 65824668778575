import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { AuthContext } from "../../contexts/AuthContext";
import { getRefreshToken } from "../../shared/helpers";

export const ProtectedRoute = ({ component, path }) => {
  const { currentUser, authToken, isAuthenticated, hasSession } = useContext(AuthContext);
  console.log({ isAuthenticated, hasSession });

  const refreshToken = getRefreshToken();
  return isAuthenticated && currentUser && (authToken || refreshToken) ? (
    <Route exact path={path} component={component} />
  ) : hasSession ? (
    <div className="d-flex justify-content-center align-items-center w-100" style={{ minHeight: "80vh" }}>
      <CircularProgress />
    </div>
  ) : (
    <Redirect to="/" />
  );
};
