import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../element/page-title";
import { TokenListDialog } from "../element/token-list-dialog";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import { useRecoilValue } from "recoil";
import { atomTargetTokenList, atomTokenPairList } from "../../state/atoms";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { CircularProgress } from "@mui/material";
import { BACKEND_FILE_URL, CHAIN_LIST } from "../../shared/constants";
import { humanReadableAccount } from "../../shared/web3";
import { ImageContainer } from "./exchange";

export const TokenInfo = () => {
  const { tokenPairRefresh, targetTokenRefresh } = useContext(AuthContext);

  const tokenPairList = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList);

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    tokenPairRefresh().then(async () => {
      await targetTokenRefresh();
    });
  }, []);

  const getUniqueTokens = () => {
    const utillityTokenList = [];
    tokenPairList.map((value, index, array) => {
      if (utillityTokenList.find((token) => token.token_id === value.token_id)) {
        return false;
      }
      utillityTokenList.push(value);
      return true;
    });

    return utillityTokenList;
  };

  return (
    <>
      <Header2 />
      <PageTitle />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <ul className="d-flex justify-content-end">
                    <li className="nav-item">
                      <Link
                        to={"#"}
                        className="nav-link text-primary token-list-link"
                        onClick={() => setModalShow(true)}
                      >
                        <i className="mdi mdi-plus-box"></i>
                        <span>List Token</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header fw-bold">
                  <h4>Token Info</h4>
                </div>
                <div className="card-body">
                  <div className="balance-table">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Token Name</th>
                            <th>Token Symbol</th>
                            <th>Network</th>
                            <th>Token Address</th>
                            <th>Public Website</th>
                            <th>Whitepapers</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(tokenPairList) && !isEmpty(targetTokenList) ? (
                            [...getUniqueTokens(), ...targetTokenList].map((token, index) => (
                              <tr key={`token-info-list-${index}`}>
                                <td className="text-white-50">#{index + 1}</td>
                                <td className="text-white-80">{token?.name}</td>
                                <td className="text-white-50">
                                  <i className="cc">
                                    <ImageContainer
                                      src={`${BACKEND_FILE_URL}/${token?.logo_url}`}
                                      style={{ width: "30px" }}
                                    />
                                  </i>
                                  {token?.symbol}
                                </td>
                                <td className="text-white-50">
                                  {CHAIN_LIST.find((value) => value.chainId === token.chain_id)?.chainName}
                                </td>
                                <td>
                                  <Link
                                    className="text-white-80"
                                    target="_blank"
                                    to={{
                                      pathname: `${
                                        CHAIN_LIST.find((chain) => chain.chainId == token?.chain_id)?.blockScanUrl
                                      }/address/${token?.address}`,
                                    }}
                                  >
                                    {humanReadableAccount(token.address)} &nbsp;
                                    <i className="la la-lg la-external-link"></i>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    className="text-white-80"
                                    target="_blank"
                                    to={{
                                      pathname: /^http[s]+:\/\//i.test(token.website_url)
                                        ? token.website_url
                                        : `https://${token.website_url}`,
                                    }}
                                  >
                                    {token.website_url} &nbsp;
                                    <i className="la la-lg la-external-link"></i>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    className={`p-2 rounded-2 me-2 ${
                                      token.short_version_pdf_url ? `bg-primary` : `bg-secondary link-disabled`
                                    }`}
                                    target="_blank"
                                    to={{ pathname: `${BACKEND_FILE_URL}/${token.short_version_pdf_url}` }}
                                  >
                                    <i className="mdi mdi-eye text-info"></i>
                                  </Link>
                                  <Link
                                    className={`p-2 rounded-2 ${
                                      token.long_version_pdf_url ? `bg-danger` : `bg-secondary link-disabled`
                                    }`}
                                    target="_blank"
                                    to={{ pathname: `${BACKEND_FILE_URL}/${token.long_version_pdf_url}` }}
                                  >
                                    <i className="mdi mdi-eye text-info"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="mx-auto">
                                <div className="d-flex justify-content-center">
                                  <CircularProgress />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TokenListDialog show={modalShow} onHide={() => setModalShow(false)} />
      </div>

      <Footer2 />
    </>
  );
};
